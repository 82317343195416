
/*
 * ============================= MapMarkerHandler =============================
 */
var toMapMarkerHandler = {
    init: function (params) {
        console.log("toMapMarkerHandler.init()",params);
        this.initConstants();
        this.markers = new Array();
        this.locationPickerMarker = null;
        this.markerClusterer = null;
        this.locations = null;
    },
    initConstants: function () {
        this._iconTypes = {
            guide: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/guide.png",
            traveler: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/traveler.png",
            recording: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/recording.png",
            request: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/request.png",
            live: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/live.png",
            me: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/me.png",
            tour: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/1.png",
            default: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/1.png",
            single: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/1.png",
        };
    },
    initAfterGoogle: function () {
        this._markerIcon = {
            strokeColor: "#ffffff",
            fillOpacity: 1,
            strokeOpacity: 1,
            anchor: new google.maps.Point(0,0),
            strokeWeight: 1,
            scale: 1,
            textSize: 16,
        };
    },
    setLocations: function (newLocations) {
        this.locations = newLocations;
    },
    getMap: function (theMap) {
        return toMapHandler.getMap(theMap);
    },
    iconTypeForMap: function (theMap) {
        var myMapDiv = this.getMap(theMap).getDiv();
        if ($(myMapDiv).hasClass("guide")) {
            return this._iconTypes.guide;
        } else if ($(myMapDiv).hasClass("traveler")) {
            return this._iconTypes.traveler;
        } else if ($(myMapDiv).hasClass("me")) {
            return this._iconTypes.me;
        } else if ($(myMapDiv).hasClass("recording")) {
            return this._iconTypes.recording;
        } else if ($(myMapDiv).hasClass("live")) {
            return this._iconTypes.live;
        } else if ($(myMapDiv).hasClass("request")) {
            return this._iconTypes.request;
        }
        return this._iconTypes.default;
    },
    addLocationPickerMarker: function (location, addressHolder, latLngHolder, theMap) {
        var myMapDiv = theMap.getDiv();
        var draggable = !$(myMapDiv).hasClass("readonly");
        var iconType = this.iconTypeForMap(theMap);
        this.locationPickerMarker = this.placeMarker(location, iconType, this.locationPickerMarker, draggable, addressHolder, latLngHolder, theMap);
    },
    clearLocationPickerMarker: function() {
        if (this.locationPickerMarker) {
            this.locationPickerMarker.setMap(null);
        }
    },
    clearAllMarkers: function() {
        console.log("clearAllMarkers(" + this.markers.length + ")");
        for (var i = 0; i < this.markers.length; i++) {
            if (this.markers[i] != null){
                this.markers[i].setMap(null);
            }
        }
        this.markers = new Array();
        return true;
    },
    unHighlightMarkers: function () {
        var icon = $.extend({}, this._markerIcon);
        icon.url = "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/2.svg";
        for (var i in this.markers) {
            this.markers[i].setIcon(icon);
        }
    },
    highlightMarker: function (marker) {
        this.unHighlightMarkers();
        var icon = $.extend({}, this._markerIcon);
        icon.url = "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/1.svg";
        marker.setIcon(icon);
        marker.setZIndex(google.maps.Marker.MAX_ZINDEX++);          // CHECK google?
    },
    updateLatLng: function (marker, latLngHolder) {
        console.log("updateLatLng(" + marker + "," + latLngHolder + ")");
        if (!latLngHolder) {
            return;
        }
        var latLngElement = (typeof latLngHolder === "string" || latLngHolder instanceof String) ? document.getElementById(latLngHolder) : latLngHolder;
        if (!latLngElement) {
            return;
        }
        //console.log(latLngHolder);
        $(latLngElement).val(marker.getPosition().lat() + "," + marker.getPosition().lng());
        $(latLngElement).trigger("change");
    },
    updateAddressForMarker: function (marker, addressHolder, latLngHolder) {
        //console.log("updateAddressForMarker()");
        var _this = this;
        this.updateLatLng(marker, latLngHolder);
        toMapHandler.gmGeoCoder.geocode({ latLng: marker.getPosition() }, function (result, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                _this.updateAddressFromGeocodeResult(result, addressHolder);
            }
        });
    },
    getAddressFromGeoCodeResult: function (result) {
        if (!result) {
            return "";
        }
        for (var i = 0; i < result[0].address_components.length; i++) {
            for (var b = 0; b < result[0].address_components[i].types.length; b++) {
                //if you want the change the area ..
                if (result[0].address_components[i].types[b] === "route") {
                    // street name
                    var street = result[0].address_components[i].short_name;
                    var streetNum = "";
                    for(var c = 0; c < result[0].address_components.length; c++){
                        for (var d = 0; d < result[0].address_components[c].types.length; d++) {
                            if (result[0].address_components[c].types[d] == "street_number") {
                                streetNum = result[0].address_components[c].short_name;
                            }
                            break;
                        }
                    }
                    return street + " " + streetNum;
                }
            }
        }
        return "";
    },
    updateAddressFromGeocodeResult: function (result, addressHolder) {
        //console.log("updateAddressFromGeocodeResult() --> ", addressHolder);
        if (this._addressHolderPendingInterval) {
            clearInterval(this._addressHolderPendingInterval);
        }
        if (!result) {
            return;
        }
        var addressHolderElement = (typeof addressHolder === "string" || addressHolder instanceof String) ? document.getElementById(addressHolder) : addressHolder;
        if (addressHolderElement) {
            addressHolderElement.value = this.getAddressFromGeoCodeResult(result);
        }
    },
    updateAddressToPending: function (addressHolder) {
        var addressHolderElement = (typeof addressHolder === "string" || addressHolder instanceof String) ? document.getElementById(addressHolder) : addressHolder;
        if (!addressHolderElement) {
            return;
        }
        addressHolderElement.value = ".";
        if (this._addressHolderPendingInterval) {
            clearInterval(this._addressHolderPendingInterval);
        }
        this._addressHolderPendingInterval = setInterval(function() {
            if (addressHolderElement.value.length  > 3) {
                addressHolderElement.value = "";
            } else {
                addressHolderElement.value += ".";
            }
        }, 100);
    },
    updateAddressWhenMarkerPositionChanges: function (marker, addressHolder, latLngHolder) {
        //console.log("updateAddressWhenMarkerPositionChanges -->");
        var _this = this;
        google.maps.event.addListener(marker, "dragend", function (e) {
            _this.updateAddressToPending(addressHolder);
            _this.updateLatLng(marker, latLngHolder);
            if (_this._addressDelay) {
                clearTimeout(_this._addressDelay);
                _this._addressDelay = null;
            }
            toMapHandler.gmGeoCoder.geocode({
                latLng: marker.getPosition()
            }, function (result, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    _this.updateAddressFromGeocodeResult(result, addressHolder);
                } else {
                    if(status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                        _this.updateAddressToPending(addressHolder);
                        _this._addressDelay = setTimeout(function() {
                            toMapHandler.gmGeoCoder.geocode({ latLng: e.latLng }, function (result, status) {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    _this.updateAddressFromGeocodeResult(result, addressHolder);
                                }
                            });
                        }, 2000);
                    }
                }
            });
        });
    },
    placeMarker: function (location, type, reuseMarker, draggable, addressHolder, latLngHolder, theMap) {
        // place marker on a google map location, for now only guide icon
        // add market object to 'reposition' the same one
        // console.log("placeMarker()", type, reuseMarker);
        var myMap = this.getMap(theMap);
        var myMapDiv = myMap.getDiv();
        var marker = reuseMarker ? reuseMarker : new google.maps.Marker({map: myMap});
        //create marker
        var icon = $.extend({}, this._markerIcon);
        icon.url = "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/1.svg";
        icon.size = new google.maps.Size(36, 41);
        icon.origin = new google.maps.Point(0, 0);
        icon.anchor = new google.maps.Point(20.5, 45);

        var options = new Object();
        options.position = location;
        options.map = myMap;
        options.draggable = Boolean(draggable);
        options.draggableCursor = null;
        options.icon = icon;
        options.label = this.makeMarkerLabel("ME");
        if (type === this._iconTypes.request) {
            options.label.text = "  ";
        }

        marker.setOptions(options);
        marker.setPosition(location);
        marker.setVisible(true);
        if (addressHolder) {
            this.updateAddressForMarker(marker, addressHolder, latLngHolder);
            this.updateAddressWhenMarkerPositionChanges(marker, addressHolder, latLngHolder);
        }
        return marker;
    },
    makeMarkerLabel: function (labelText) {
        return { text: labelText, fontFamily: "bebasNeuebold", fontWeight: "800", fontSize: "16px", color: "#FFFFFF" };
    },
    setClickHandlerForMarker: function (marker, i) {
        if ($("#map").hasClass("clustered") && !$("#map").hasClass("with-locations")) {
            google.maps.event.addListener(marker, "click", (function (marker, i) {
                return function() {
                    toMapHandler.findPlaceAndGotoMap(marker.getPosition());
                }
            })(marker, i));
        } else if (toMapHandler.activeTypes.length > 0) {
            google.maps.event.addListener(marker, "click", (function (marker, i) {
                return function() {
                    toMapHandler.showWindow(marker);
                }
            })(marker, i));
        }
    },
    markerPosition: function(resultItem) {
        var position = new Object();
        position.lat = parseFloat(resultItem.lat);
        position.lng = parseFloat(resultItem.lng);
        return position;
    },        
    markerForLocation: function (location, map, i, type) {
        //console.log("markerForLocation",type);
        // console.log("toMapMarkerHandler.markerForLocation()", location,this.markerPosition(location));
        var markers = new Array();
        var icon = $.extend({}, this._markerIcon);
        var options = new Object();
        icon.url = "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/2.svg";
        options.icon = icon;
        var position =this.markerPosition(location);
        options.position = position;
        options.map = map;
        if (type === "guidecluster") {
            options.label = this.makeMarkerLabel(String(location.content.guides));
        } else if (type === "requestcluster") {
            options.label = this.makeMarkerLabel(String(location.content.requests));
            options.label.fontFamily = "proxima";
        } else {
            options.label = this.makeMarkerLabel("1");
        }
        // console.log("markerForLocation", options);
        var marker = new google.maps.Marker(options);
        marker.location = location;
        marker.type = type;
        this.setClickHandlerForMarker(marker, i);
        return marker;
    },
    getClusterStyles: function () {
        return [
            {
                textColor: "#FFFFFF",
                textSize: 16,
                url: "https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/search-result/1.png",
                height: 52,
                width: 53
            }
        ];
    },
    filterState: function () {
        if ($("input.filter-select[value=1]").length > 0) {
            if ($("input.filter-select[value=1]").length > 1) {
                return "";
            } else if ($("input.filter-select[value=1]").attr("id").startsWith("requests")) {
                return "requests";
            }
        }
        return "guides";
    },
    showLocations: function (newLocations, append) {
        var _this = toMapMarkerHandler; // ! set to global because this method is an event handler for googlemaps !
        if (newLocations) {
            if (append && _this.locations) {
                _this.locations = _this.locations.concat(newLocations);
            } else {
                _this.locations = newLocations;
            }
        }
        console.log("toMapMarkerHandler.showLocations()", _this.locations);
        if (_this.locations) { // this was eval("locations") but let's just require parsed json here
            //_this.boundsForAllLocations = new google.maps.LatLngBounds(); // never used?
            _this.clearAllMarkers();
            if ($("#map").hasClass("clustered") && $("#map").hasClass("with-locations") && $("#app-layout").hasClass("home")) {
                _this.markers = [].concat.apply([], _this.locations.map(function (location, i) {
                    var marker = _this.markerForLocation(location, _this.getMap());
                    google.maps.event.addListener(marker, "click", (function (marker, i) {
                        //console.log("marker click");
                        return function() {
                            var filter = (marker.location.type === "request" ? "requests" : "guides");
                            toMapHandler.gotoMapForBounds(_this.boundsForLatLng(marker.getPosition()), filter);
                        }
                    })(marker, i));
                    return [marker];
                }));
            } else {
                _this.markers = [].concat.apply([], _this.locations.map(function (location, i) {
                    return [_this.markerForLocation(location, _this.getMap())];
                }));
            }

            // var clusterOptions = new Object();
            // clusterOptions.styles = _this.getClusterStyles();
            // clusterOptions.maxZoom = toMapHandler._max_zoom_cluster;
            // if ($("#map").hasClass("clustered") && $("#map").hasClass("with-locations") && !$("#app-layout").hasClass("home")) {
            //     //clusterOptions.zoomOnClick = true; // default
            // } else if ($("#map").hasClass("clustered")) {
            //     clusterOptions.zoomOnClick = false;
            // }
            // if (_this.markerClusterer) {
            //     _this.markerClusterer.clearMarkers();
            // }
            // _this.markerClusterer = new MarkerClusterer(_this.getMap(), _this.markers, clusterOptions);
            // google.maps.event.addListener(_this.markerClusterer, "clusterclick", function(cluster) {
            //     toMapHandler.gotoMapForBounds(_this.markerClusterer.getExtendedBounds(cluster.getBounds()), _this.filterState());
            // });
        }
    },
    boundsForLatLng: function (latlng) {
        var bounds = new google.maps.LatLngBounds(latlng, latlng);
        bounds.extend({ lat: parseFloat(latlng.lat()) - 0.1 , lng: parseFloat(latlng.lng()) - 0.1 });
        bounds.extend({ lat: parseFloat(latlng.lat()) + 0.1 , lng: parseFloat(latlng.lng()) + 0.1 });
        return bounds;
    },
    openMarkerWindow: function (infoItemElement) {
        //console.log("toMapMarkerHandler.openMarkerWindow");
        var marker = this.markerForElement(infoItemElement);
        if (marker) {
            toMapHandler.showWindow(marker);
        }
    },
    markerForElement: function (element) {
        var marker;
        var parts = element.attr("id").split("-");
        var id = parts[parts.length - 1];
        var type;
        if (element.hasClass("request")) type = "request";
        if (element.hasClass("recording")) type = "recording";
        if (element.hasClass("live")) type = "live";
        if (element.hasClass("guide")) type = "guide";
        if (element.hasClass("traveler")) type = "traveler";
        console.log("markerForElement(" + element.attr("id") + ") --> " + type + " " + id);
        for (index in this.markers) {
            marker = this.markers[index];
            if (marker.location.type === type && marker.location.id == id) {
                //console.log("found marker", marker);
                return marker;
            }
        }
    },
};

toMapMarkerHandler.init();

/*
 *
 *
 * ============================= MapHandler - map related functions =============================
 *
 *
 */
var toMapHandler = {
    init: function (params) {
        console.log("toMapHandler.init()",params);
        var _this = this;
        this.initConstants();
        // init props
        this._theOneMap = null;
        this.showingWindow = false;
        this.activeTypes = new Array();
        $(document).ready(function () {
            _this.initDomReady();
        });
    },
    initDomReady: function () {
        this.checkForActiveTypes();
        if (theone.show_map) {
            toMapMarkerHandler.setLocations(theone.search_results.results);
            this.setInitialMapBounds(theone.criteria.bounds);
            this.setInitialMapPlace(theone.criteria.place);
        }

    },
    initConstants: function () {
        this._max_zoom_cluster = 33;
    },
    setMap: function (map) {
        this._theOneMap = map;
    },
    getMap: function (map) {
        if (map) {
            return map;
        }
        return this._theOneMap;
    },
    initMap: function () {
        console.log("toMapHandler.initMap()");
        if (!document.getElementById("map")) {
            return;
        }
        var zoom = 3;
        if ($("#app-layout").hasClass("tours")) {
            zoom = 1;
        }
        var mapOptions = {
            scrollwheel: false,
            zoom: zoom,
            center: {lat: 15, lng: 15},
            minZoom: 6,
            maxZoom: 15,
            streetViewControl: false,
            mapTypeControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP]
            },
            styles : [
                  {
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#f5f5f5"
                      }
                    ]
                  },
                  {
                    "elementType": "labels.icon",
                    "stylers": [
                      {
                        "visibility": "off"
                      }
                    ]
                  },
                  {
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#616161"
                      }
                    ]
                  },
                  {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                      {
                        "color": "#f5f5f5"
                      }
                    ]
                  },
                  {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#bdbdbd"
                      }
                    ]
                  },
                  {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#eeeeee"
                      }
                    ]
                  },
                  {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#757575"
                      }
                    ]
                  },
                  {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#e5e5e5"
                      }
                    ]
                  },
                  {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#9e9e9e"
                      }
                    ]
                  },
                  {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#ffffff"
                      }
                    ]
                  },
                  {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#757575"
                      }
                    ]
                  },
                  {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#dadada"
                      }
                    ]
                  },
                  {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#616161"
                      }
                    ]
                  },
                  {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#9e9e9e"
                      }
                    ]
                  },
                  {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#e5e5e5"
                      }
                    ]
                  },
                  {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#eeeeee"
                      }
                    ]
                  },
                  {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#c9c9c9"
                      }
                    ]
                  },
                  {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#9e9e9e"
                      }
                    ]
                  }
            ]
        }
        console.log("toMapHandler.initMap() initialMapLocation: ",this.initialMapLocation);
        if (this.initialMapLocation && this.initialMapLocation.length > 0) {
            mapOptions.zoom = 4;
            mapOptions.center = this.stringToLatLng(this.initialMapLocation);
        }
        var map = new google.maps.Map(document.getElementById("map"), mapOptions);
        this.setMap(map);
        //console.log("mapinitdone", document.getElementById("map"));
        google.maps.event.addListenerOnce(map, "idle", this.moveToLocation);
        google.maps.event.addListenerOnce(map, "idle", toMapMarkerHandler.showLocations);
        google.maps.event.addListenerOnce(map, "idle", this.centerOnPlace);
    },
    stringToLatLng: function (ll) {
        var latlng = ll.split(/, ?/);
        return new google.maps.LatLng(parseFloat(latlng[0]), parseFloat(latlng[1]));
    },
    setGeoCoder: function (geocoder) {
        this.gmGeoCoder = geocoder;
    },
    setInitialMapLocation: function (location) {
        this.initialMapLocation = location;
    },
    setInitialMapBounds: function (bounds) {
        this.initialMapBounds = bounds;
    },
    setInitialMapPlace: function (place) {
        this.initialMapPlace = place;
    },
    moveToLocation: function (theMap) {
        var _this = toMapHandler; // ! GooleMaps event handler, set _this directly to global !
        var myMap = _this.getMap(theMap);
        var myMapDiv = myMap.getDiv();
        if ($(myMapDiv).hasClass("with-locations")) {
            return;
        }
        console.log("GooleMaps event - moveToLocation");
        var latLngHolder = _this.getHolderOfMap(toAutocompleteHandler.latLngHolderId, myMapDiv);
        var addressHolder = _this.getHolderOfMap(toAutocompleteHandler.addressHolderId, myMapDiv);
        var latlng = (typeof latLngHolder === "string" || latLngHolder instanceof String) ? $("#" + latLngHolder).val() : $(latLngHolder).val();
        var address = toGlobalStateContainer.MAP.address;
        if ($("input[name=location_latlng_userprofile]").length) {
            latlng = $("input[name=location_latlng_userprofile]").val();
        }
        //console.log("latlngholder:" , latLngHolder , "location_latlng:",latlng);
        if (latlng && latlng.length > 0 && latlng.split(",").length === 2) {
            latlng = latlng.split(",");
            var location = new google.maps.LatLng(latlng[0], latlng[1]);
            _this.centerMapOnLocation(location, myMap);
            toMapMarkerHandler.addLocationPickerMarker(location, addressHolder, latLngHolder, myMap);
        } else if (address && address.length > 0) {
            _this.gmGeoCoder.geocode({
                "address": address
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    _this.centerMapOnLocation(results[0].geometry.location, myMap);
                } else {
                    console.log("Geocode ERROR: " + status);
                }
            });
        } else {
            _this.geolocate(myMap);
        }
    },
    centerOnPlace: function (theMap) {
        var _this = toMapHandler; // ! GooleMaps event handler, set _this directly to global !
        var myMap = _this.getMap(theMap);
        var myMapDiv = myMap.getDiv();
        console.log("GoogleMaps event - centerOnPlace");
        if (!$(myMapDiv).hasClass("with-locations")) {
            return;
        }
        if (_this.initialMapBounds) {
            console.log("fit bounds to initialMapBounds" , _this.initialMapBounds);
            myMap.fitBounds(_this.boundsFromString(_this.initialMapBounds));
            _this.addMapChangeListener(myMap);
            return;
        }
        if (!_this.initialMapPlace) {
            return;
        }
        console.log("centerOnPlace / initialMapPlace", _this.initialMapPlace);
        _this.gmGeoCoder.geocode({
            address: _this.initialMapPlace
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                _this.focusMapOnLocation(results[0], myMap);
            } else {
                console.log("Geocode ERROR: " + status);
            }
        });
    },
    boundsFromString: function (str) {
        var arr = str.split(",");
        var sw = new google.maps.LatLng( arr[0], arr[1] );
        var ne = new google.maps.LatLng( arr[2], arr[3] );
        var bounds = new google.maps.LatLngBounds( sw, ne );
        return bounds;
    },
    keepFilterOnPlaceId: function (newPlaceID) {
        //this.placeID = newPlaceID; // not used anywhere
        if ($("input#place_id")) {
            $("input#place_id").val(newPlaceID);
        }
    },
    focusMapOnLocation: function (place, theMap) {
        console.log("focusMapOnLocation", place, theMap);
        var myMap = this.getMap(theMap);
        var myMapDiv = myMap.getDiv();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("Could not find '" + place.name + "' on map, please try again.");
            return false;
        }
        if (place.place_id) {
            this.keepFilterOnPlaceId(place.place_id);
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
            myMap.fitBounds(place.geometry.viewport);
        } else {
            this.centerMapOnLocation(place.geometry.location, myMap);
        }
        this.addMapChangeListener(myMap);
        return true;
    },
    centerMapOnLocation: function (location, theMap) {
        console.log("toMapHandler.centerMapOnLocation()",location);
        var myMap = this.getMap(theMap);
        myMap.setCenter(location);
        myMap.setZoom(11);
        $(document).trigger("resize");
    },
    unHighlightItems: function () {
        $(".info-item").removeClass("selected");
    },
    highlightItem: function (type, id) {
        console.log("highlightItem(" + type + "," + id + ")");
        // instead of highlighting we should start showing
        this.unHighlightItems();
        // if (type=="guide" || type=="traveler") {
            $("#info-user-" + id + ".info-item").addClass("selected");
        // } else if (type=="request") {
        //     console.log($("#info-tour-proposal-" + id + ".info-item"));
        //     $("#info-tour-proposal-" + id + ".info-item").addClass("selected");
        // } else if (type=="recording" || type=="live") {
        //     $("#info-virtual-tour-" + id + ".info-item").addClass("selected");
        // }
        // scroll selected to the top
        $("#main-container").animate({ scrollTop: $("#map-results").scrollTop() + $(".info-item.selected").position().top + "px" });
    },
    showWindow: function (marker) {
        console.log('showWindow',marker);
        var myMap = this.getMap();
        this.showingWindow = true;
        myMap.panTo(marker.location);
        if (myMap.getZoom() <= this._max_zoom_cluster) {
            myMap.setZoom(this._max_zoom_cluster + 2);
        }
        toMapMarkerHandler.highlightMarker(marker);
        this.highlightItem(marker.location.type, marker.location.id);
    },
    addMapChangeListener: function (theMap) {
        var _this = this;
        var myMap = this.getMap(theMap);
        google.maps.event.addListenerOnce(myMap, "idle", function() {
            myMap.addListener("dragend", function (e) {
                _this.mapChanged(myMap);
            });
            myMap.addListener("zoom_changed", function (e) {
                _this.mapChanged(myMap);
            });
        });
    },
    getHolderOfMap: function (holderName, mapDiv) {
        if (mapDiv) {
            var holder = $(mapDiv).parents("form").first().find("input[name=" + holderName + "]");
            if (holder.length) {
                return holder[0];
            }
        }
        return holderName;
    },
    mapChanged: function (theMap) {
        if (!this.showingWindow) {
            this.keepFilterOnBounds(theMap.getBounds().toUrlValue());
        }
        this.showingWindow = false;
    },

    keepFilterOnBounds: function (newBounds) {
        var boundsInput = $("input#criteria\\[bounds\\]");
        var oldBounds = boundsInput.val();
        console.log("keepFilterOnBounds() --> " , oldBounds, newBounds);
        if (oldBounds != newBounds) {
            boundsInput.val(newBounds);
            if ($("#form-search-options").length) {
                $("#form-search-options").data('inline',true);
                $("#form-search-options").submit();
            } else if ($("#form-tours").length) {
                $("#form-tours").submit();
            }
        }
    },
    gotoMapForBounds: function (bounds, filter) {
        return; // this functionality should be handled by the mapChanged - TODO CHECK
        if (bounds) {
            if (filter) {
                document.location.href = "/map?mapcluster=1&filter=" + filter + "&place=&id=&place_id=&bounds=" + bounds.toUrlValue();
            } else {
                document.location.href = "/map?mapcluster=1&filter=guides&place=&id=&place_id=&bounds=" + bounds.toUrlValue();
            }
        }
    },
    checkForActiveTypes: function () {                      // -------- TODO doesn't this belong to searchHandler?
        var filteredTypes = $("#filter.type-filter").val(); // --------- TODO this is too generic
        if (filteredTypes != undefined) {
            filteredTypes = filteredTypes.split(",");
            for (index in filteredTypes) {
                var type = filteredTypes[index];
                switch (type) {
                    case "guides"       : this.activeTypes.push("guide"); break;
                    case "travelers"    : this.activeTypes.push("traveler"); break;
                    case "requests"     : this.activeTypes.push("request"); break;
                    case "recordings"   : this.activeTypes.push("recording"); break;
                    case "live"         : this.activeTypes.push("live"); break;
                }
            }
        }
        //console.log("checkForActiveTypes: ", this.activeTypes);
    },
    findPlaceAndGotoMap: function (position, filter) {
        var _this = this;
        toGenericHelper.showLoader();
        if (this._addressDelay) {
            clearTimeout(this._addressDelay);
            this._addressDelay = null;
        }
        this.gmGeoCoder.geocode({
            latLng: position
        }, function (result, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                _this.gotoMapForPlace(result, filter);
            } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                _this._addressDelay = setTimeout(function() {
                    _this.gmGeoCoder.geocode({ latLng: position }, function (result, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            _this.gotoMapForPlace(result, filter);
                        }
                    });
                }, 2000);
            }
        });
    },
    countryNameFromGeoCodeResult: function (result) {
        if (result == undefined || result.address_components == undefined) return undefined;
        for (i in result.address_components) {
            var component = result.address_components[i];
            if (component.types != undefined && $.inArray("country", component.types) >= 0) {
                return component.long_name;
            }
        }
        return undefined;
    },
    countryResultFromGeoCodeResults: function (results) {
        //console.log("countryResultFromGeoCodeResults");
        // found out that for instance getting data for a result in barcelona will result in undefined, because catalunia is not a "country"
        // how to handle that. maybe instead of country go to place id with a fixed bounds around that
        if (results == undefined) return undefined;
        for (var i = results.length - 1 ; i >= 0 ; i--) {
            var result = results[i];
            if (result.types != undefined && $.inArray("country", result.types) >= 0) {
                return result;
            }
        }
        return results[results.length-1];
    },
    gotoMapForPlace: function (results, filter) {
        console.log("toMapHandler.gotoMapForPlace()");
        var result, bounds, country_name, url;
        if (!results) {
            toGenericHelper.hideLoader();
            return;
        }
        result = this.countryResultFromGeoCodeResults(results);
        if (!result) {
            toGenericHelper.hideLoader();
            return;
        }
        bounds = result.geometry.viewport ? result.geometry.viewport.toUrlValue() : "";
        country_name = this.countryNameFromGeoCodeResult(result);
        url = "/map?place=" + country_name + "&place_id=" + result.place_id + "&bounds=" + bounds;
        if (filter) {
            url += "&filter=" + filter;
        }
        document.location.href = url;
    },
    initNewRequestMap: function (requestInitialMapLocation) {
        //console.log("initNewRequestMap()");
        return this.initNewRequestMapOnElement(requestInitialMapLocation, "request-map");
    },
    initNewRequestMapOnElement: function (requestInitialMapLocation, elementId) {
        var mapElement = document.getElementById(elementId);
        if (!mapElement) {
            return;
        }
        var _this = this;
        var mapOptions = {
            scrollwheel: false,
            zoom: 3,
            center: {lat: 33, lng: 0},
            minZoom: 1,
            maxZoom: 15,
            streetViewControl: false,
            mapTypeControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP]
            }
        }
        if (this.initialMapLocation && this.initialMapLocation.length > 0) {
            console.log("we have an initialMapLocation", this.initialMapLocation);
            mapOptions.zoom = 4;
            var locationLatLng = this.stringToLatLng(this.initialMapLocation);
            mapOptions.center = locationLatLng;
        } else if (requestInitialMapLocation) {
            var locationLatLng = this.stringToLatLng(requestInitialMapLocation);
            mapOptions.center = locationLatLng;
        }
        var newRequestMap = new google.maps.Map(mapElement, mapOptions);
        console.log("newrequestmapinitdone");
        google.maps.event.addListenerOnce(newRequestMap, "idle", function() {
            _this.moveToLocation(newRequestMap);
        });
        // google.maps.event.addListenerOnce(this.getMap(), "idle", toMapMarkerHandler.showLocations);
        // google.maps.event.addListenerOnce(this.getMap(), "idle", toMapHandler.centerOnPlace);
        return newRequestMap;
    },
    geolocate: function (theMap) {
        // Bias the autocomplete object to the user's geographical location,
        // as supplied by the browser's 'navigator.geolocation' object.
        //console.log("geolocate");
        var _this = this;
        var myMap = this.getMap(theMap);
        var myMapDiv = myMap.getDiv();
        var latLngHolder = this.getHolderOfMap(toAutocompleteHandler.latLngHolderId, myMapDiv);
        var addressHolder = this.getHolderOfMap(toAutocompleteHandler.addressHolderId, myMapDiv);
        this.getUserLocation(function (location) {
            _this.centerMapOnLocation(location, myMap);
            if ($(myMapDiv).hasClass("with-user-position")) {
                toMapMarkerHandler.clearLocationPickerMarker();
                toMapMarkerHandler.addLocationPickerMarker(location, addressHolder, latLngHolder, myMap);
            }
        });
    },
    geolocateForStartLocation: function (latlngHolder) {
        this.getUserLocation(function (location) {
            $(latlngHolder).val(location.lat + "," + location.lng);
        });
    },
    getUserLocation: function (callBack) {
        // var default_location = { lat: 52.279432, lng: 4.868553 };
        var default_location = { lat: -6.202394, lng: 106.652710 };
        //console.log("getUserLocation");
        if (navigator.geolocation) {
            //console.log("locating");
            navigator.geolocation.getCurrentPosition(function (result) {
                //console.log("geolocation success");
                var location = {
                    lat: result.coords.latitude,
                    lng: result.coords.longitude
                };
                callBack(location);
            }, function(e) {
                console.log("geolocation unsuccessful", e);
                callBack(default_location);
            });
        } else {
            console.log("geolocation not in browser / not allowed");
            callBack(default_location);
        }
        //console.log("getUserLocation done");
    },
    boundsForLatLngObject: function(latlng) {
        return [
            parseFloat(latlng.lat) - 0.1,
            parseFloat(latlng.lng) - 0.1,
            parseFloat(latlng.lat) + 0.1,
            parseFloat(latlng.lng) + 0.1,
        ];
    },
};

toMapHandler.init();

/* // these below are not used anymore?
function markerPath(location) {
    return "M-35,-12 L35,-12 L35,12 L7.5,12 L0,18 L-7.5,12, L-35,12, L-35,-12";
}
function smallMarkerPath(location) {
    return "M-15,-12 L15,-12 L15,12 L7.5,12 L0,18 L-7.5,12, L-15,12, L-15,-12";
}
function meMarkerPath(location) {
    return "M-17.5,-12 L17.5,-12 L17.5,12 L7.5,12 L0,18 L-7.5,12, L-17.5,12, L-17.5,-12";
}
function RPath() {
    return "M308,400.2h-1.1L303,396h-3.1v4.2h-0.9V389c1.6,0,3.3,0,4.9,0c2.4,0,3.7,1.7,3.7,3.5c0,1.8-1.2,3.4-3.6,3.4L308,400.2z M299.9,395.1h4c2,0,2.8-1.1,2.8-2.7c0-1.3-0.9-2.7-2.7-2.7h-4.1V395.1z";
}
*/
